import React from 'react';

const NoFormsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
    {/* Folder shape */}
    <path
      d="M10 40 Q 20 30, 30 40 H 70 Q 80 30, 90 40 V 75 Q 90 85, 80 85 H 20 Q 10 85, 10 75 Z"
      fill="#f0f0f0"
      stroke="#b3b3b3"
      strokeWidth="2"
    />
    {/* Document inside the folder */}
    <rect x="35" y="45" width="30" height="35" rx="3" fill="white" stroke="#b3b3b3" strokeWidth="1.5" />
    {/* Curved dashed lines representing text */}
    <path d="M40 55 Q 50 53, 60 55" stroke="#b3b3b3" strokeDasharray="4 4" strokeWidth="2" />
    <path d="M40 65 Q 50 63, 60 65" stroke="#b3b3b3" strokeDasharray="4 4" strokeWidth="2" />
    <path d="M40 75 Q 50 73, 60 75" stroke="#b3b3b3" strokeDasharray="4 4" strokeWidth="2" />
    {/* Circular "no" sign over the document */}
    <circle cx="50" cy="62" r="12" stroke="lightgray" strokeWidth="2" fill="none" />
    <line x1="42" y1="54" x2="58" y2="70" stroke="lightgray" strokeWidth="2" />
  </svg>
);

export default NoFormsIcon;
