import React from 'react';
import ProLogo from '../reactcomponents/logo';
import NavButtons from '../reactcomponents/NavButtons';
import { CircleUserRound } from 'lucide-react';
import { Button } from '../@/components/ui/button';
import { useAuth0 } from "@auth0/auth0-react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/@/components/ui/dropdown-menu";



const SideNavbar = () => {
    const { user, logout } = useAuth0();

    return (
        <div className='h-screen flex flex-col justify-between'>
            <div>
                <ProLogo />
                <div className='flex mt-[25px] flex-col space-y-5' >
                    <NavButtons />
                </div>
            </div>
            <div className='flex flex-col space-y-5 sticky bottom-[45px]'>

                <DropdownMenu>
                    <DropdownMenuTrigger>
                        <Button className="flex items-center justify-start bg-white text-black hover:bg-[#D9D9D9] w-full ">
                            <CircleUserRound className="mr-3 h-[25px] w-[25px]" /><p className='text-lg'>{user.name}</p>
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-56">
                        <DropdownMenuLabel>My Account</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem className="cursor-pointer">Settings</DropdownMenuItem>
                        <DropdownMenuItem onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })} className="cursor-pointer">Logout</DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>

            </div >
        </div>
    );
};

export default SideNavbar;