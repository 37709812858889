import React from 'react';
import { Home, Search } from 'lucide-react';
import { Button } from '../@/components/ui/button';

import { useNavigate } from 'react-router-dom';

const NavButtons = () => {
    const navigate = useNavigate();

    const handleButtonClick = (path) => {
        navigate(path); // Path to routes
    };

    return (
        <>
            <Button onClick={() => { handleButtonClick('/home'); }} className="flex items-center justify-start p-6 bg-white text-black hover:bg-[#D9D9D9] w-full">
                <Home className="mr-3 h-[25px] w-[25px]" /><p className='text-lg'>Home</p>
            </Button>
            <Button onClick={() => { handleButtonClick('/search'); }} className="flex items-center justify-start p-6 bg-white text-black hover:bg-[#D9D9D9] w-full">
                <Search className="mr-3 h-[25px] w-[25px]" /><p className='text-lg'>Search</p>
            </Button>
        </>
    );
};

export default NavButtons;