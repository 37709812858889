import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SideNavbar from './reactcomponents/SideNavbar';
import NotFound from './reactcomponents/NotFound';
import LoginPage from './reactcomponents/LoginPage';
import ProtectedRoute from './reactcomponents/ProtectedRoute';
import Homepage from './reactcomponents/Homepage';
import NewInterview from './reactcomponents/NewInterview';
import ContactForm from './reactcomponents/ContactForm';
import ProLogo from './reactcomponents/logo';
import SurveyIntro from './reactcomponents/SurveyIntro';
import { SurveyProvider } from './reactcomponents/SurveyContext';
import EditInterview from './reactcomponents/EditInterview';
import SubmissionsPage from './reactcomponents/SubmissionsTabContent';
import Callpage from './reactcomponents/CallPage';

function App() {
  return (
    <>
      <SurveyProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<Navigate to="/home" replace />
            }
            />
            <Route path="/home" element={
              <ProtectedRoute>
                <div className='flex' >
                  <div className="py-[45px] ml-[28px] w-fit pr-20 h-screen flex flex-col border-r-2 border-[#BFBFBF] fixed">
                    <SideNavbar />
                  </div >
                  <div className="flex flex-col w-full h-full overflow-y-auto ml-[380px] p-20">
                    <Homepage />
                  </div>
                </div >
              </ProtectedRoute>
            } />
            <Route path="/search" element={
              <ProtectedRoute>
                <div className='flex' >
                  <div className="py-[45px] ml-[28px] w-fit pr-20 h-screen flex flex-col border-r-2 border-[#BFBFBF] fixed">
                    <SideNavbar />
                  </div >
                  <div className="flex flex-col w-full h-full overflow-y-auto ml-[380px] p-20">
                    <SubmissionsPage />
                  </div>
                </div >
              </ProtectedRoute>}
            />
            <Route path="/newform" element={
              <ProtectedRoute>

                <div className="py-[45px] ml-[28px] w-fit pr-20 h-screen flex flex-col border-r-2 border-[#BFBFBF] fixed">
                  <SideNavbar />
                </div >
                <NewInterview />

              </ProtectedRoute>
            } />
            <Route path="/editinterview" element={
              <ProtectedRoute>

                <div className="py-[45px] ml-[28px] w-fit pr-20 h-screen flex flex-col border-r-2 border-[#BFBFBF] fixed">
                  <SideNavbar />
                </div >
                <EditInterview />
              </ProtectedRoute>
            } />
            {/* <Route path="/sampleform" element={ } /> */}
            <Route path="/:id" element={
              <>
                <div className="py-[45px] ml-[28px] w-fit pr-10 h-screen flex flex-col fixed">
                  <div className='h-screen flex flex-col justify-between'>
                    <div>
                      <ProLogo />
                    </div>
                  </div>
                </div >
                <div className="flex flex-col pt-40 ">
                  <ContactForm />
                </div>
              </>
            } />
            <Route path="/survey-intro/:id" element={
              <>
                <div className="py-[45px] ml-[28px] w-fit pr-10 h-screen flex flex-col fixed">
                  <div className='h-screen flex flex-col justify-between'>
                    <div>
                      <ProLogo />
                    </div>
                  </div>
                </div >
                <div className="flex flex-col pt-40 ">
                  <SurveyIntro />
                </div>
              </>
            } />
            <Route path="/call/:id" element={
              <>
                <div className="py-[45px] ml-[28px] w-fit pr-10 h-screen flex flex-col fixed">
                  <div className='h-screen flex flex-col justify-between'>
                    <div>
                      <ProLogo />
                    </div>
                  </div>
                </div >
                <div className="flex flex-col ">
                  <Callpage />
                </div>
              </>

            } />
            <Route path="/contactform/surveyintro/call/confirmation" element={
              <ProtectedRoute>

                <div className="py-[45px] ml-[28px] w-fit pr-10 h-screen flex flex-col fixed">
                  <div className='h-screen flex flex-col justify-between'>
                    <div>
                      <ProLogo />
                    </div>
                  </div>
                </div >
                <div className="flex flex-col ">
                  <div className='flex flex-col items-center justify-center h-screen'>
                    <h1 className='text-2xl font-bold'>Thank you for your submission!</h1>
                  </div>
                </div>

              </ProtectedRoute>
            } />
            {/* <Route path="/logout" element={
            <div>YOUVE BEEN LOGGED OUT</div>
          } /> */}
            <Route path="*" element={<NotFound />} />
          </Routes>

        </Router >
      </SurveyProvider>
    </>
  );
}

export default App;;