// src/components/LoginPage.js
import React from 'react';
import LogoutNavbar from './LogoutNavbar';


const LoginPage = () => {
  return (
    <div className='flex' >
      <div className="py-[45px] ml-[28px] w-fit pr-20 h-screen flex flex-col border-r-2 border-[#BFBFBF] fixed">
        <LogoutNavbar />
      </div >
      <div className="flex flex-col w-full h-full overflow-y-auto ml-[380px] p-20">
        <div className="p-4">

          <div className="flex justify-between border-b-[1px] border-RGB(237,237,236) mb-8 pb-2">
            <h1 className="text-2xl font-bold mb-2">Home</h1>
            <div className="flex justify-between mb-2">
              {/* <button className="bg-blue-600 text-white py-1 px-3 rounded-md text-sm">New form</button> */}
            </div>
          </div>
          <div className="bg-white p-8 rounded shadow-md w-full text-center mt-10">
            <h2 className="text-2xl font-bold mb-6">Please log in to continue and view your forms</h2>
            <p className="text-gray-700 mb-4">Log in to access all the features and forms available in the app.</p>
          </div>
        </div>
      </div>
    </div >

  );
};

export default LoginPage;



