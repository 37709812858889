import { Ellipsis, Link, PenLine, Trash } from "lucide-react";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/@/components/ui/dialog";
import { Button } from "@/@/components/ui/button";
import { useAuth0 } from "@auth0/auth0-react";
import NoFormsIcon from "./icons/NoFormsIcon";


const Home = () => {
  const [forms, setForms] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { user } = useAuth0();

  //hello there updating most recent changes asdfalkjsadf
  const fetchForms = useCallback(async () => {
    try {
      const response = await axios.get(`https://api.tryaptitude.ai/get-forms/${user.sub}`);
      const reversedForms = response.data.forms.reverse();
      setForms(reversedForms);
    } catch (error) {
      console.error('Error fetching forms:', error);
    } finally {
      setLoading(false);
    }
  }, [user.sub]);

  useEffect(() => {
    if (user && user.sub) {
      fetchForms();
    }
  }, [fetchForms, user]);


  const deleteForm = async (id) => {
    try {
      await axios.delete(`https://api.tryaptitude.ai/delete-form/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      fetchForms();
    } catch (error) {
      console.error('Error deleting form:', error);
    }
  };


  const handleNewFormClick = () => {
    navigate('/newform');
  };

  const handleFormClick = (formId) => {
    navigate('/editinterview', { state: { formId } });
  };

  return (
    <div className="p-4">
      <div className="flex justify-between  border-b-[1px] border-RGB(237,237,236) mb-8 pb-2">
        <h1 className="text-2xl font-bold mb-2">Home</h1>
        <div className="flex justify-between mb-2">
          <button onClick={handleNewFormClick} className="bg-blue-600 text-white py-1 px-3 rounded-md text-sm">New form</button>
        </div>
      </div>
      {loading ? <div>Loading...</div> :
        <div>
          {forms.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-full text-center p-4">
              <NoFormsIcon />
              <p className="text-lg text-gray-600 mb-4">No forms available. Click the button above to create a new form.</p>
            </div>
          ) : (
            <div>
              {forms.map((form, index) => (
                <div key={index} className="  hover:cursor-pointer mb-2 p-2 px-4 rounded-lg shadow-sm hover:bg-[#ededec] group" >
                  <div className="flex justify-between items-center">
                    <div onClick={() => { handleFormClick(form.id); }} className=" w-[1000px]">
                      <h2 className="text-sm font-semibold">{form.title}</h2>
                      <p className="text-gray-600 text-sm">
                        {form.submissions} submissions
                        {form.edited && ` · Edited ${form.edited}`}
                      </p>
                    </div>

                    <div className="flex space-x-1 opacity-0 group-hover:opacity-100 transition-opacity duration-100">
                      <button className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center">
                        <span className="scale-75">
                          <PenLine />
                        </span>
                      </button>
                      <button className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center">
                        <span className="scale-75">
                          <Link />
                        </span>
                      </button>
                      <Dialog>
                        <DialogTrigger asChild>
                          <button className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center ">
                            <span className="scale-75">
                              <Trash />
                            </span>
                          </button>
                        </DialogTrigger>
                        <DialogContent className="sm:max-w-[425px]">
                          <DialogHeader>
                            <DialogTitle>Delete Confirmation</DialogTitle>
                            <DialogDescription>
                              Are you sure you want to delete this interview? ({form.title}) Once deleted, this interview will not be recoverable as well as its submissions.
                            </DialogDescription>
                          </DialogHeader>
                          <DialogFooter>
                            <DialogTrigger asChild>
                              <Button type="submit" className="bg-red-500 hover:bg-red-700" onClick={() => {
                                deleteForm(form.id);
                              }}
                              >Delete Interview</Button>
                            </DialogTrigger>
                          </DialogFooter>
                        </DialogContent>
                      </Dialog>
                      <button className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center">
                        <span className="scale-75">
                          <Ellipsis />
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>}
    </div>
  );
};

export default Home;

