import React from 'react';
import ProLogo from '../reactcomponents/logo';
import NavButtons from '../reactcomponents/NavButtons';
import { CircleUserRound } from 'lucide-react';
import { Button } from '../@/components/ui/button';
import { useAuth0 } from "@auth0/auth0-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/@/components/ui/dropdown-menu";



const LogoutNavbar = () => {
  const { loginWithRedirect } = useAuth0();


  <button onClick={() => loginWithRedirect()} className="px-4 py-2 mt-6 text-white bg-blue-500 rounded hover:bg-blue-600">
    Go to Login
  </button>;
  return (
    <div className='h-screen flex flex-col justify-between'>
      <div>
        <ProLogo />
        <div className='flex mt-[25px] flex-col space-y-5' >
          <NavButtons />
        </div>
      </div>
      <div className='flex flex-col space-y-5 sticky bottom-[45px]'>

        <DropdownMenu>
          <DropdownMenuTrigger>
            <Button className="flex items-center justify-start bg-white text-black hover:bg-[#D9D9D9] w-full ">
              <CircleUserRound className="mr-3 h-[25px] w-[25px]" /><p className='text-lg'>Login Here</p>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem className="cursor-pointer">Settings</DropdownMenuItem>
            <DropdownMenuItem onClick={() => loginWithRedirect()} className="cursor-pointer">Login</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>

      </div >
    </div>
  );
};

export default LogoutNavbar;