import { Settings, PenLine } from "lucide-react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { Button } from "@/@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/@/components/ui/dialog";
import { useLocation } from "react-router-dom";
import Spinner from "./Spinner";
import { Bounce, ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditTabContent from "./EditTabContent";
import ShareTabContent from "./ShareTabContent";
import SubmissionsTabContent from "./SubmissionsTabContent";


const EditInterview = () => {
    const [interview, setInterview] = useState({});
    const [previewLink, setPreviewLink] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    // const [isDraft, setIsDraft] = useState(true);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false); // 
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false); // track  unsaved changes
    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('submissions');
    const link = `${window.location.origin}/${interview.id}`;

    useEffect(() => {
        const link = `${window.location.origin}/${interview.id}`;
        setPreviewLink(link);
        if (!interview.id && location.state.formId) {
            setInterview({ id: location.state.formId });
        }
    }, [location, interview.id]);

    useEffect(() => {
        if (location.state.formId) {
            const fetchInterview = async () => {
                try {
                    const response = await axios.get(`https://api.tryaptitude.ai/get-form/${location.state.formId}`);
                    setInterview(response.data.form);
                } catch (error) {
                    console.error('Error fetching form:', error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchInterview();
        }
    }, [location]);

    // useEffect(() => {
    //     if (location.state?.interview) {
    //         setInterview(location.state.interview);
    //     }
    // }, [location.state]);


    const handleTitleChange = (event) => {
        setInterview((prevState) => {
            return {
                ...prevState,
                title: event.target.value
            };
        });
    };

    const enableEdit = () => {
        setIsEditingTitle(true);
    };

    const disableEdit = () => {
        setIsEditingTitle(false);
        saveTitle();
    };

    const handleHomeClick = () => {
        navigate('/home');
    };

    // const handlePreviewClick = () => {
    //     navigate(`/survey-intro/${interview.id}`, { state: { form: interview } });
    // };

    const handlePreviewClick = () => {
        navigate(`/survey-intro/${interview.id}`, { state: { form: interview } });
    }

    const handleInfoChange = (e) => {
        setInterview({
            ...interview,
            interviewInfo: e.target.value
        });
        setHasUnsavedChanges(true);
    };

    const handleInstructionsChange = (e) => {
        setInterview({
            ...interview,
            interviewInstructions: e.target.value
        });
        setHasUnsavedChanges(true);
    };

    const handleNameChange = (e) => {
        setInterview({
            ...interview,
            interviewName: e.target.value
        });
        setHasUnsavedChanges(true);
    };

    const saveTitle = async () => {
        try {
            await axios.put(`https://api.tryaptitude.ai/update-form/${interview.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                title: interview.title || "New Interview",
                interviewInfo: interview.interviewInfo,
                interviewInstructions: interview.interviewInstructions,
                interviewName: interview.interviewName
            });
        } catch (error) {
            console.error('Error updating form:', error);
        }
    };

    const saveInfo = async () => {
        setIsSubmitted(true);
        setHasUnsavedChanges(false);
        const link = `${window.location.origin}/survey-intro/${interview.id}`;
        setPreviewLink(link);
        const interviewId = interview.id; // Use the existing interview ID
        try {
            await axios.put(`https://api.tryaptitude.ai/update-form/${interviewId}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                title: interview.title || "New Interview",
                interviewInfo: interview.interviewInfo,
                interviewInstructions: interview.interviewInstructions,
                interviewName: interview.interviewName
            });
            // navigate(0);
        } catch (error) {
            console.error('Error updating form:', error);
        }
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(previewLink);
        toast.success('Link copied!');
    };

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen ml-[332px]">
                <Spinner />
            </div>
        );
    }


    return (
        <div className="pt-[35px] px-[50px] overflow-y-auto ml-[380px] p-6">
            <div className="flex justify-between">
                <nav className="text-sm text-gray-600 mb-4">
                    <button onClick={handleHomeClick} className="hover:underline">My workspace</button> &gt; <span className="">{interview.title}</span>
                </nav>
                <ToastContainer
                    position="bottom-right"
                    autoClose={1000}
                    limit={1}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    transition={Bounce}
                    theme="colored"
                />
                {activeTab === "edit" && <div className="flex justify-end text-center items-center">
                    {!hasUnsavedChanges && <div className="bg-gray-200 text-gray-700 text-xs font-bold pt-1 text-center rounded-md p-1 mr-3">Published</div>}
                    {hasUnsavedChanges && <div className="bg-blue-400 text-white text-xs font-bold pt-1 text-center rounded-md p-1 mr-3">Changes</div>}
                    <button className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center mr-4">
                        <span className="scale-75">
                            <Settings />
                        </span>
                    </button>
                    {isSubmitted ? <div className="mr-3 text-gray-500 text-sm font-medium">Preview</div> : <button onClick={handlePreviewClick} className="mr-3 text-blue-500 text-sm font-medium">Preview</button>}
                    {interview.interviewName && interview.interviewInfo && interview.interviewInstructions && hasUnsavedChanges ?
                        <Dialog>
                            <DialogTrigger asChild>
                                <button className="bg-blue-500 text-white py-1 px-2 text-xs font-medium rounded-md">Publish</button>
                            </DialogTrigger>
                            <DialogContent className="sm:max-w-[425px]">
                                <DialogHeader>
                                    <DialogTitle>Confirmation</DialogTitle>
                                    <DialogDescription>
                                        Are you sure you want to publish this interview? Once published, the interview will be live.
                                    </DialogDescription>
                                </DialogHeader>
                                <DialogFooter>
                                    <Button className="bg-blue-500 hover:bg-blue-800" onClick={() => {
                                        saveInfo();
                                        // setIsDraft(false);
                                        toast.success('Changes saved!', {
                                            position: "top-right",
                                            autoClose: 2000,
                                            hideProgressBar: true,
                                            closeOnClick: true,
                                            pauseOnHover: false,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                            transition: Slide,
                                        });
                                    }}
                                    >Publish</Button>
                                </DialogFooter>
                            </DialogContent>
                        </Dialog>
                        :
                        <div className=" text-gray-600 text-sm font-medium">Publish</div>
                    }
                </div>}
            </div>
            <h1 className="text-2xl font-bold mb-2 flex">
                {isEditingTitle ? (
                    <input
                        type="text"
                        value={interview?.title}
                        onChange={handleTitleChange}
                        onBlur={disableEdit}
                        onKeyDown={(event) => event.key === 'Enter' && disableEdit()}
                        className="text-2xl font-bold mb-2"
                        autoFocus
                    />
                ) : (
                    <>
                        {interview?.title}
                        <button
                            className="text-[#9e9e9b] hover:text-gray-800 flex items-center justify-center ml-2"
                            onClick={enableEdit}
                        >
                            <div className="scale-75">
                                <PenLine />
                            </div>
                        </button>
                    </>
                )}
            </h1>
            <div className="flex border-b mb-4">
                <button
                    className={`p-2 ${activeTab === 'submissions' ? 'border-b-2 border-blue-500' : ''}`}
                    onClick={() => setActiveTab('submissions')}
                >
                    Submissions
                </button>
                <button
                    className={`p-2 ${activeTab === 'edit' ? 'border-b-2 border-blue-500' : ''}`}
                    onClick={() => setActiveTab('edit')}
                >
                    Edit
                </button>
                <button
                    className={`p-2 ${activeTab === 'share' ? 'border-b-2 border-blue-500' : ''}`}
                    onClick={() => setActiveTab('share')}
                >
                    Share
                </button>
            </div>
            {activeTab === 'share' && (
                <ShareTabContent
                    link={link}
                    handleCopyClick={handleCopyClick}
                />
            )}
            {activeTab === 'edit' && (
                <EditTabContent
                    interview={interview}
                    handleNameChange={handleNameChange}
                    handleInfoChange={handleInfoChange}
                    handleInstructionsChange={handleInstructionsChange}
                />
            )}
            {activeTab === 'submissions' && (
                <SubmissionsTabContent
                    interview={interview}
                />
            )}
        </div >
    );
};

export default EditInterview;
