import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root'));

root.render(
  <Auth0Provider
    domain="dev-subl5j6tip7g4loa.us.auth0.com"
    clientId="iRwphQhmza9MPBnyjPXKAQ6n9e4j2Wj4"
    authorizationParams={{
      redirect_uri: "https://app.tryaptitude.ai/home"
    }}
  >
    <App />
  </Auth0Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


export const isPublicKeyMissingError = ({ vapiError }) => {
  return !!vapiError && vapiError.error.statusCode === 403 && vapiError.error.error === "Forbidden";
};
