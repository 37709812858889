import React from 'react';
import logo from "../logo.png";

const ProLogo = () => {
    return (
        <div className="flex items-center ">
            <div><img src={logo} width={65} height={57} alt="Logo" /></div>
            <p className="text-3xl ml-2 font-bold">Echoform</p>
        </div>
    );
};

export default ProLogo;