// import { Ellipsis, Link, PenLine, Trash } from "lucide-react";
import React, { useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/@/components/ui/table";
import { Input } from "@/@/components/ui/input";
import { format } from 'date-fns';

const SubmissionsTabContent = ({ interview }) => {
    // const [forms, setForms] = useState([]);
    const [submissions, setSubmissions] = useState([]);
    // const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');


    // const fetchForms = async () => {
    //     try {
    //         const response = await axios.get('http://localhost:5000/get-forms');
    //         const reversedForms = response.data.forms.reverse();
    //         setForms(reversedForms);
    //         console.log(reversedForms);
    //     } catch (error) {
    //         console.error('Error fetching forms:', error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const fetchSubmissions = async () => {
        try {
            const response = await axios.get(`https://api.tryaptitude.ai/get-responses/${interview.id}`);
            const submissions = response.data.responses;
            if (submissions) {
                const reversedSubmissions = submissions.reverse();
                setSubmissions(reversedSubmissions);
            } else {
                console.error('No submissions found in the response');
            }
        } catch (error) {
            console.error('Error fetching submissions:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([fetchSubmissions()]);
            setLoading(false);
        };
        fetchData();
    }, []);

    const filteredSubmissions = submissions.filter(submission => {
        const firstName = submission.firstName || '';
        const lastName = submission.lastName || '';
        const email = submission.email || '';
        const phoneNumber = submission.phoneNumber || '';
        const interviewSummary = submission.interview_summary || '';
        const interviewTranscript = submission.interview_transcript || '';
        const interviewRecording = submission.interview_recording || '';
        const query = searchQuery.toLowerCase();
        return (
            firstName.toLowerCase().includes(query) ||
            lastName.toLowerCase().includes(query) ||
            email.toLowerCase().includes(query) ||
            phoneNumber.toLowerCase().includes(query) ||
            interviewSummary.toLowerCase().includes(query) ||
            interviewTranscript.toLowerCase().includes(query) ||
            interviewRecording.toLowerCase().includes(query)
        );
    });

    // const handleFormClick = (formId) => {
    //     navigate('/editinterview', { state: { formId } });
    // };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!loading && filteredSubmissions.length === 0) {
        return (
            <div className="flex flex-col items-center justify-center h-full py-10">
                <svg
                    className="w-16 h-16 mb-4 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 12h6m-6 4h6m2 4H7a2 2 0 01-2-2V6a2 2 0 012-2h7l5 5v11a2 2 0 01-2 2z"
                    ></path>
                </svg>
                <h2 className="text-xl font-semibold text-gray-700">No Submissions Found</h2>
                <p className="text-gray-500">Share your link to start receiving submissions</p>
            </div>
        );
    }
    return (
        <div className="p-4">
            <div className="flex justify-end pb-2">
                <div className="flex mb-2">
                    <Input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
            </div>
            <Table>
                <TableCaption>Submissions</TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead>Submitted At</TableHead>
                        <TableHead>First Name</TableHead>
                        <TableHead>Last Name</TableHead>
                        <TableHead>Phone Number</TableHead>
                        <TableHead>Email</TableHead>
                        <TableHead>Interview Summary</TableHead>
                        <TableHead>Interview Transcript</TableHead>
                        <TableHead>Interview Recording</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {filteredSubmissions.map((submission, index) => (
                        <TableRow key={index}>
                            <TableCell className="font-medium">
                                {format(new Date(submission.submitted_at), 'MMM d, h:mma')}
                            </TableCell>
                            <TableCell>{submission.firstName}</TableCell>
                            <TableCell>{submission.lastName}</TableCell>
                            <TableCell>{submission.phoneNumber}</TableCell>
                            <TableCell>{submission.email}</TableCell>
                            <TableCell>{submission?.interview_summary}</TableCell>
                            <TableCell>{submission?.interview_transcript}</TableCell>
                            <TableCell>{submission?.interview_recording}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default SubmissionsTabContent;

