import React from 'react';

const EditTabContent = ({ interview, handleNameChange, handleInfoChange, handleInstructionsChange }) => {
  return (
    <div>
      <div className="mb-4">
        <div className="flex items-center">
          <h2 className="text-lg font-semibold">Interview Name</h2>
          <span className="text-red-500 font-bold text-xl ml-1">*</span>
        </div>
        <p className="text-gray-600 text-sm mb-2">Please provide a name to the interviewee</p>
        <input
          className="border rounded-lg p-2"
          value={interview.interviewName}
          onChange={handleNameChange}
        ></input>
      </div>
      <div className="mb-4">
        <div className="flex items-center">
          <h2 className="text-lg font-semibold">Interview Information</h2>
          <span className="text-red-500 font-bold text-xl ml-1">*</span>
        </div>
        <p className="text-gray-600 text-sm mb-2">Please provide information to the interviewee about the context of this interview</p>
        <textarea
          className="w-full h-32 border rounded-lg p-2"
          value={interview.interviewInfo}
          onChange={handleInfoChange}
        ></textarea>
      </div>
      <div className="mb-4">
        <div className="flex items-center">
          <h2 className="text-lg font-semibold">Interview Instructions</h2>
          <span className="text-red-500 font-bold text-xl ml-1">*</span>
        </div>
        <p className="text-gray-600 text-sm mb-2">Describe what your AI interviewer should ask about during interview.</p>
        <textarea
          className="w-full h-32 border rounded-lg p-2"
          value={interview.interviewInstructions}
          onChange={handleInstructionsChange}
        ></textarea>
      </div>
    </div>
  );
};

export default EditTabContent;